<template>
  <div class="video-channel">
    <div class="group-box">
      <el-select
        v-model="activeGroup"
        placeholder="Please select"
        size="small"
        ref="selectGroup"
        @blur="blurGroup"
        @change="groupChanges"
        @visible-change="visibleChange"
      >
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
        <div class="add-group" @click="addGroup">+ Create New Group</div>
      </el-select>
      <div class="pop-group" v-show="showGroup" @click="blurGroup()"></div>
      <div
        style="padding: 6px 10px"
        ref="editBox"
        class="edit-box"
        @click="showEdit = !showEdit"
      >
        <i class="el-icon-more" style="transform: rotate(90deg)"></i>
        <div class="edit-group" v-show="showEdit">
          <div @click="addGroup('edit')">Edit Group</div>
          <div class="del-group" @click="openDeleteGroup">Delete Group</div>
        </div>
        <div
          v-show="showEdit"
          class="edit-pop"
          @click.stop="showEdit = false"
        ></div>
      </div>
    </div>
    <div class="videoShow scrollor" v-show="videoArr.length">
      <div class="video-box" v-for="item in videoArr" :key="item.id">
        <video
          disablePictureInPicture
          :ref="'myVideo' + item.id"
          :id="'myVideo' + item.id"
          :featuresFullscreenResize="true"
          x5-playsinline=""
          playsinline=""
          webkit-playsinline=""
          class="videoTv video-js vjs-default-skin vjs-big-play-centered"
          controls="controls"
          name="media"
          muted="muted"
          preload="auto"
          loop
        >
          <source :src="item.url" type="application/x-mpegURL" />
        </video>
        <div class="video-bottom">
          <div class="group-name ellipsis">{{ item.name }}</div>
          <i
            class="el-icon-delete group-delete"
            @click="openChannelDel(item.id)"
          ></i>
        </div>
      </div>
    </div>
    <div class="add-btn" @click="addChannel">
      <img src="../assets/images/add.png" alt="" />
    </div>
    <!-- 创建和编辑group -->
    <el-dialog
      :title="groupTitle"
      :visible.sync="dialogVisibleGroup"
      :width="isPc ? '30%' : '92%'"
      :before-close="handleCloseGroup"
    >
      <div class="group-input">
        <span>Name</span>
        <el-input v-model="groupName"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCloseGroup">Cancel</el-button>
        <el-button size="small" type="success" @click="saveGroup"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除group -->
    <el-dialog
      :visible.sync="dialogDelGroup"
      :width="isPc ? '30%' : '92%'"
      class="del-dialog"
    >
      <div>
        There are
        <span class="orange-color">{{ videoArr.length }}</span> channels in this
        group.
      </div>
      <div>Are you sure you still want to delete it?</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogDelGroup = false">No</el-button>
        <el-button size="small" type="success" @click="deleteGroup"
          >Yes</el-button
        >
      </span>
    </el-dialog>
    <!-- 创建channel -->
    <el-dialog
      title="Add Channel"
      :visible.sync="dialogVisibleChannel"
      :width="isPc ? '30%' : '92%'"
      :before-close="handleCloseChannel"
    >
      <div class="group-input mb-24">
        <span>Name</span>
        <el-input v-model="channelName"></el-input>
      </div>
      <div class="group-input">
        <span>URL</span>
        <el-input v-model="channelUrl"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCloseChannel">Cancel</el-button>
        <el-button size="small" type="success" @click="saveChannel"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除channel -->
    <el-dialog
      :visible.sync="dialogDelChannel"
      :width="isPc ? '30%' : '92%'"
      class="del-dialog"
    >
      <div>Are you sure you want to delete this Channel?</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogDelChannel = false">No</el-button>
        <el-button size="small" type="success" @click="deleteChannel"
          >Yes</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
// import lodash from "lodash";
import config from "../assets/js/config.js";
import { getUrlParam } from "@/assets/js/utils";

export default {
  name: "GridVideo",
  data() {
    return {
      video: null,
      activeGroup: "",
      groupList: [], // 存缓存内的所有group
      dialogVisibleGroup: false,
      groupName: "",
      showEdit: false,
      dialogDelGroup: false,
      dialogVisibleChannel: false,
      channelName: "",
      channelUrl: "",
      dialogDelChannel: false,
      videoArr: [],
      currentGroupId: "",
      channelId: "",
      allChannelList: [], //存缓存内的所有channel
      groupTitle: "",
      showGroup: false,
    };
  },
  computed: {
    isPc() {
      return this.$store.state.isPc;
    },
    isUpdated() {
      return this.$store.state.isUpdated;
    },
  },
  // vjs-control-bar
  created() {
    console.log(this.isPc, "------isPC", this.isUpdated, "------isUpdated");
    if (
      localStorage.getItem("groupList") &&
      localStorage.getItem("groupList") != "null"
    ) {
      this.groupList = JSON.parse(localStorage.getItem("groupList"));
      this.allChannelList = JSON.parse(localStorage.getItem("allChannelList"));
      if (this.groupList.length > 0) {
        if (
          localStorage.getItem("activeGroup") &&
          localStorage.getItem("activeGroup") != "null"
        ) {
          this.activeGroup = localStorage.getItem("activeGroup");
        } else {
          this.activeGroup = this.groupList[0].value;
        }
      } else {
        this.activeGroup = null;
      }
      // app端版本更新之后
      if (!this.isPc && this.isUpdated) {
        // 通知app版本更新
        const u = navigator.userAgent;
        const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
        if (isAndroid) {
          // 安卓机型
          // eslint-disable-next-line no-undef
          clearCacheAndReloadWebView.postMessage(JSON.stringify({}));
        } else {
          // ios机型
          if (window && window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.clearCacheAndReloadWebView.postMessage(
              JSON.stringify({})
            );
          }
        }
        localStorage.setItem("channelLinkVersion", "1.0.15");
      }
      if (getUrlParam("adid")) {
        this.allChannelList.forEach((item) => {
          if (item.url.includes("?ads.deviceid")) {
            item.url = item.url.split("?ads.deviceid=")[0];
          }
          if (item.url.includes("?DAdID")) {
            item.url = item.url.split("?DAdID=")[0];
          }
          if (getUrlParam("UIP")) {
            item.url = item.url + this.connectUlrParams();
          } else {
            item.url = item.url + "?ads.deviceid=" + getUrlParam("adid");
          }
        });
      }
      localStorage.setItem(
        "allChannelList",
        JSON.stringify(this.allChannelList)
      );
      const arr = this.allChannelList.filter(
        (item) => item.groupId == this.activeGroup
      );
      this.videoArr = JSON.parse(JSON.stringify(arr));
    } else {
      // 首次进入设置默认组及其channel
      this.groupList.push({ label: "My group", value: uuidv4() });
      localStorage.setItem("groupList", JSON.stringify(this.groupList));
      config.channelList.forEach((item) => {
        item.groupId = this.groupList[0].value;
      });
      config.appChannelList[0].groupId = this.groupList[0].value;
      if (this.isPc) {
        this.allChannelList = config.channelList;
      } else {
        this.allChannelList = config.appChannelList;
      }
      if (getUrlParam("adid")) {
        this.allChannelList.forEach((item) => {
          if (getUrlParam("UIP")) {
            item.url = item.url + this.connectUlrParams();
          } else {
            item.url = item.url + "?ads.deviceid=" + getUrlParam("adid");
          }
        });
      }
      this.videoArr = JSON.parse(JSON.stringify(this.allChannelList));
      localStorage.setItem(
        "allChannelList",
        JSON.stringify(this.allChannelList)
      );
      this.activeGroup = this.groupList[0].value;
    }
    // this.activeGroup = this.groupList.length > 0 ? this.groupList[0].value : "";
    localStorage.setItem("activeGroup", this.activeGroup);
  },
  mounted() {
    this.offPicture();
    let noSleep = new this.$NoSleep();
    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        noSleep.enable();
        console.log(
          `Element: ${document.fullscreenElement.id} entered full-screen mode.`
        );
      } else {
        // noSleep.disable();
        console.log("Leaving full-screen mode.");
      }
    });
    this.getVideo();
  },
  methods: {
    connectUlrParams() {
      // url拼接文档要求地址：https://docs.google.com/document/d/1GxoyDmGAzTnqLg_wfFL8vyedbMkfFonWSl4rct49DMA/edit
      // 参数文档：https://us.demo.daiconnect.com/doc/player/query-params/
      const userAgent = navigator.userAgent;
      const isAndroid =
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; // android终端
      let appId, appUrl, deviceType;
      if (isAndroid) {
        appId = "com.tvustream.channellink";
        appUrl =
          "https://play.google.com/store/apps/details?id=com.tvustream.channellink";
        deviceType = encodeURIComponent("adid:Android");
      } else {
        appId = "com.tvunetworks.TVUChannelLink";
        appUrl = "https://apps.apple.com/us/app/channel-link/id6444000826";
        deviceType = encodeURIComponent("idfa:Apple phones (iOS)");
      }
      const paramsList = [
        { name: "?DAdID=", value: getUrlParam("adid") },
        { name: "&DAdIDT=", value: deviceType },
        { name: "&DT=", value: this.isPc ? "2" : "1" },
        { name: "&UIP=", value: getUrlParam("UIP") },
        { name: "&UUA=", value: encodeURIComponent(userAgent) },
        { name: "&ApBdl=", value: appId },
        { name: "&ApStURL=", value: appUrl },
        { name: "&NPshrD=", value: "http://channellink.tvustream.com" },
        { name: "&UPP=", value: "1" },
        { name: "&DDNT=", value: getUrlParam("DDNT") },
        { name: "&ULAdT=", value: getUrlParam("ULAdT") },
        { name: "&UGDPR=", value: "0" },
        { name: "&UCOPPA=", value: "0" },
        { name: "&UUSPriv=", value: "0" },
      ];
      let url = "";
      paramsList.forEach((item) => {
        url += item.name + item.value;
      });
      return url + "%3A";
    },
    offPicture() {
      this.timer = setInterval(function () {
        if ($(".vjs-picture-in-picture-control")) {
          $(".vjs-picture-in-picture-control").remove();
          $(".vjs-picture-in-picture-control").attr("disabled", "disabled");
          clearInterval(this.timer);
        }
      }, 500);
    },
    getVideo() {
      if (this.videoArr.length < 1) return;
      this.$nextTick(() => {
        this.videoArr.forEach((item) => {
          videojs(
            "myVideo" + item.id,
            {
              bigPlayButton: false,
              textTrackDisplay: false,
              posterImage: true,
              errorDisplay: false,
              controlBar: true,
              language: "es",
            },
            function () {
              setTimeout(() => {
                // this.play();
                let playPromise = this.play();
                if (playPromise !== undefined) {
                  playPromise
                    .then(() => {
                      this.play();
                      // Automatic playback started!
                      // Show playing UI.
                    })
                    .catch((error) => {
                      console.log(error);
                      // Auto-play was prevented
                      // Show paused UI.
                    });
                }
              }, 0);
            }
          );
        });
      });
    },
    // 创建group
    addGroup(type) {
      if (type == "edit") {
        this.groupTitle = "Edit group";
        if (this.groupList.length < 1) {
          this.showEdit = false;
          return this.$message.warning("Please create a group first");
        }
        const obj = this.groupList.find(
          (item) => item.value === this.activeGroup
        );
        this.groupName = obj.label;
        this.currentGroupId = this.activeGroup;
      } else {
        this.groupTitle = "Create New Group";
      }
      this.dialogVisibleGroup = true;
    },
    handleCloseGroup() {
      this.dialogVisibleGroup = false;
      this.groupName = "";
      this.currentGroupId = "";
    },
    saveGroup() {
      if (!this.groupName) return this.$message.error("Please enter name");
      if (this.currentGroupId) {
        // 编辑group
        this.groupList.forEach((item) => {
          if (item.value === this.currentGroupId) {
            item.label = this.groupName;
          }
        });
        this.showEdit = false;
      } else {
        // 新建
        const id = uuidv4();
        this.groupList.push({ label: this.groupName, value: id });
        this.activeGroup = id;
        this.groupChanges(this.activeGroup);
      }
      localStorage.setItem("groupList", JSON.stringify(this.groupList));
      this.handleCloseGroup();
    },
    openDeleteGroup() {
      if (this.groupList.length < 1) {
        this.showEdit = false;
        return this.$message.warning("Please create a group first");
      }
      this.dialogDelGroup = true;
    },
    deleteGroup() {
      // 删除group下的channel
      const list = this.allChannelList.filter(
        (item) => item.groupId != this.activeGroup
      );
      localStorage.setItem("allChannelList", JSON.stringify(list));
      this.allChannelList = JSON.parse(JSON.stringify(list));
      // 删除group
      const groupList = this.groupList.filter(
        (item) => item.value != this.activeGroup
      );
      localStorage.setItem("groupList", JSON.stringify(groupList));
      this.groupList = JSON.parse(JSON.stringify(groupList));
      // 展示第一个group及其channel
      this.activeGroup =
        this.groupList.length > 0 ? this.groupList[0].value : "";
      this.groupChanges(this.activeGroup);
      this.$message.success("success");
      this.dialogDelGroup = false;
      this.showEdit = false;
    },
    // 添加channel
    addChannel() {
      this.dialogVisibleChannel = true;
    },
    handleCloseChannel() {
      this.dialogVisibleChannel = false;
      this.channelName = "";
      this.channelUrl = "";
    },
    saveChannel() {
      if (!this.channelName) return this.$message.error("Please enter name");
      if (!this.channelUrl) return this.$message.error("Please enter url");
      if (getUrlParam("adid")) {
        this.channelUrl =
          this.channelUrl + "?ads.deviceid=" + getUrlParam("adid");
      }
      this.allChannelList.push({
        url: this.channelUrl,
        id: uuidv4(),
        name: this.channelName,
        groupId: this.activeGroup,
      });
      localStorage.setItem(
        "allChannelList",
        JSON.stringify(this.allChannelList)
      );
      this.handleCloseChannel();
      // this.groupChanges(this.activeGroup);
      const arr = this.allChannelList.filter(
        (item) => item.groupId == this.activeGroup
      );
      this.videoArr = JSON.parse(JSON.stringify(arr));
      this.getVideo();
    },
    openChannelDel(id) {
      this.channelId = id;
      this.dialogDelChannel = true;
    },
    deleteChannel() {
      const allList = this.allChannelList.filter(
        (item) => item.id != this.channelId
      );
      this.allChannelList = JSON.parse(JSON.stringify(allList));
      localStorage.setItem(
        "allChannelList",
        JSON.stringify(this.allChannelList)
      );
      const list = this.videoArr.filter((item) => item.id != this.channelId);
      this.videoArr = JSON.parse(JSON.stringify(list));
      this.dialogDelChannel = false;
    },
    groupChanges(val) {
      localStorage.setItem("activeGroup", val);
      this.clearVideo();
      const arr = this.allChannelList.filter((item) => item.groupId == val);
      this.videoArr = JSON.parse(JSON.stringify(arr));
      this.getVideo();
    },
    clearVideo() {
      if (this.videoArr.length < 1) return;
      this.videoArr.forEach((item) => {
        videojs("myVideo" + item.id).dispose();
        $("myVideo" + item.id).html("");
      });
      // $(".videoShow").html("");
    },
    blurGroup() {
      setTimeout(() => {
        this.$refs.selectGroup.blur();
      }, 100);
    },
    visibleChange(val) {
      this.showGroup = val;
    },
  },
};
</script>

<style scoped lang="less">
.video-channel {
  padding: 0 16px;
  .group-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
  }
  /deep/.el-input__inner {
    background-color: #444444;
    border-color: #444444;
    color: #dbdbdb;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner {
    border-color: #33ab4f;
  }
  /deep/.el-select {
    width: 85%;
  }
}
.add-group {
  color: #69ad48;
  padding: 5px 12px 0;
  border-top: solid 1px #666666;
  line-height: 36px;
  z-index: 10;
  cursor: pointer;
}
.group-input {
  display: flex;
  align-items: center;
  span {
    color: #dbdbdb;
    display: inline-block;
    width: 60px;
  }
}
.edit-group {
  position: absolute;
  z-index: 10;
  width: 126px;
  background: #444444;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  right: 10px;
  padding: 12px;
  .del-group {
    margin-top: 10px;
  }
}
.add-btn {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  cursor: pointer;
}
.video-box {
  .video-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    background-color: #000000;
    margin-bottom: 17px;
    height: 32px;
    line-height: 32px;
    .group-name {
      color: #33ab4f;
    }
    .group-delete {
      color: #999999;
      font-size: 15px;
      line-height: 32px;
      padding: 0 10px;
      cursor: pointer;
    }
  }
}
.videoTv {
  width: 100%;
  height: 195px;
  //height: calc(100% - 40px);
}
.edit-pop,
.pop-group {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 9;
}
/deep/.el-select-dropdown__item {
  z-index: 10;
}
.videoShow {
  overflow: auto;
  height: calc(100vh - 172px);
  padding-bottom: 64px;
}
@media (min-width: 500px) {
  .video-channel {
    padding-right: 0;
  }
  .group-box {
    margin-top: 20px;
  }
  .videoShow {
    overflow: auto;
    height: calc(100vh - 210px);
  }
  .video-box {
    width: calc(50% - 16px);
    float: left;
    margin-right: 15px;
  }
  .edit-box {
    cursor: pointer;
    padding-right: 26px !important;
  }
}
@media (min-width: 761px) {
  .video-channel {
    width: 80%;
    margin: 0 auto;
    .video-box {
      height: 410px;
    }
    .videoTv {
      height: calc(100% - 40px);
    }
    .edit-group {
      right: 67px;
    }
    .add-btn {
      right: 50px;
      bottom: 10px;
    }
  }
}
@media (min-width: 1920px) {
  .video-channel {
    max-width: 1920px;
  }
}
</style>
