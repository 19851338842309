import { v4 as uuidv4 } from "uuid";
const channelList = [
  {
    url: "https://d437su47pv9ij.cloudfront.net/v1/master/b540341037514ce955e2d08d3ab12a1d8b5c7965/StrykTV/master.m3u8",
    id: uuidv4(),
    name: "StrykTV",
  },
  {
    url: "https://dbp4ufhu57n29.cloudfront.net/v1/master/b540341037514ce955e2d08d3ab12a1d8b5c7965/Tenband/master.m3u8",
    id: uuidv4(),
    name: "Tenband TV",
  },
  {
    url: "https://dhzwy5cc2t7yz.cloudfront.net/v1/master/b540341037514ce955e2d08d3ab12a1d8b5c7965/MediaTailor7_CDN/index.m3u8",
    id: uuidv4(),
    name: "ViveTV",
  },
  {
    url: "https://d3h5kf3azblkd8.cloudfront.net/v1/master/b540341037514ce955e2d08d3ab12a1d8b5c7965/MediaTailor6_CDN/index.m3u8",
    id: uuidv4(),
    name: "CKYTV",
  },
  {
    url: "https://8810237cdeeb4d2d82e04e2cc5755157.mediatailor.us-east-1.amazonaws.com/v1/master/b540341037514ce955e2d08d3ab12a1d8b5c7965/aiptest/master.m3u8",
    id: uuidv4(),
    name: "TVU",
  },
  {
    url: "https://d1f82svi7kiayv.cloudfront.net/v1/master/b540341037514ce955e2d08d3ab12a1d8b5c7965/NewsForceTV/master.m3u8",
    id: uuidv4(),
    name: "NewsForce",
  },
];
const appChannelList = [
  {
    url: "https://8810237cdeeb4d2d82e04e2cc5755157.mediatailor.us-east-1.amazonaws.com/v1/master/b540341037514ce955e2d08d3ab12a1d8b5c7965/aiptest/master.m3u8",
    id: uuidv4(),
    name: "TVU",
  },
];
export default {
  channelList,
  appChannelList,
};
