export const getUrlParam = function (name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r =
    window.location.search.substr(1).match(reg) ||
    window.location.hash
      .substr(window.location.hash.indexOf('?') + 1)
      .match(reg)

  if (r != null) {
    return unescape(r[2])
  }
  return null
}
